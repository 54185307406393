import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import styled from 'styled-components';
import 'react-vertical-timeline-component/style.min.css';
import { RoadmapItem } from './types';

interface RoadmapDivProps {
  height?: string;
  backgroundColor?: string;
  reverseGridOnMobile?: boolean;
}

const RoadmapDiv = styled.div<RoadmapDivProps>`
  background-color: ${(props) => props.backgroundColor || 'auto'};
  width: 100%;

  & .vertical-timeline-element {
    overflow-x: hidden; // this solves the mobile issue with the timeline jumping off screen
    margin: 10px 0px;

    & .bounce-in {
      padding-bottom: 0;
    }

    & .vertical-timeline-element {
      overflow-x: visible;
    }

    & .vertical-timeline-element-icon {
      width: 32px;
      height: 32px;
      margin: 4px 0 0 4px;
      overflow-x: visible; // this solves the mobile issue with the timeline jumping off screen

      & svg {
        width: 70%;
        height: 70%;
        padding: 4px 0 0 2px;
      }
    }

    & .vertical-timeline--one-column-left {
      overflow-x: hidden; // this solves the mobile issue with the timeline jumping off screen
      pading-bottom: 0;
      background-color: red;
      color: red;
    }
  }
`;

interface RoadmapProps {
  events: RoadmapItem[];
}

const Roadmap = ({ events }: RoadmapProps) => {
  return (
    <RoadmapDiv>
      <VerticalTimeline layout="1-column" className="">
        {events.map(
          ({ title, subtitle, content, dateText, contentStyle, iconStyle, icon }, idx) => (
            <VerticalTimelineElement
              key={idx}
              contentStyle={{
                ...contentStyle,
              }}
              style={{ padding: '0', paddingBottom: '20px' }}
              contentArrowStyle={{ borderRight: `7px solid  ${contentStyle.background}` }}
              iconStyle={iconStyle}
              icon={icon}
            >
              <p
                style={{
                  marginTop: '0',
                  color: contentStyle.color,
                  fontSize: '24px',
                  lineHeight: '25px',
                  fontFamily: 'Montserrat Alternates',
                }}
              >
                {title}
              </p>
              <h4
                style={{
                  fontSize: '22px',
                  fontWeight: '500',
                  fontFamily: 'Montserrat',
                  marginTop: '15px',
                }}
              >
                {subtitle}
              </h4>
              <p style={{ fontSize: '18px', fontWeight: 'normal', fontFamily: 'Montserrat' }}>
                {dateText}
              </p>
              {content.map((contentItem, contentIdx) => (
                <p
                  key={contentIdx}
                  style={{
                    fontSize: '18px',
                    fontWeight: 'normal',
                    fontFamily: 'Montserrat',
                  }}
                >
                  {contentItem}
                </p>
              ))}
            </VerticalTimelineElement>
          ),
        )}
      </VerticalTimeline>
    </RoadmapDiv>
  );
};

export default Roadmap;
