import { CompaniesLineItem } from 'components/common/companiesLine/types';

const COMPANY_LINE_IMAGE_ROOT = '/images/content/companiesLine';

const makeCompanyImageUrl = (name: string) => `${COMPANY_LINE_IMAGE_ROOT}/${name}`;

const IMAGE_SCALE = 0.64;

export const COMPANIES_LINE_ITEMS: CompaniesLineItem[] = [
  {
    image: makeCompanyImageUrl('google.png'),
    name: 'Google',
    height: IMAGE_SCALE * 100,
    width: IMAGE_SCALE * 285,
    scale: 1,
    left: 0.5,
    top: 0.22,
  },
  {
    image: makeCompanyImageUrl('bloomberg.png'),
    name: 'Bloomberg',
    height: IMAGE_SCALE * 60,
    width: IMAGE_SCALE * 285,
    scale: 1,
    left: 0.1,
    top: 0.7,
  },
  {
    image: makeCompanyImageUrl('uplift.png'),
    name: 'Uplift.ltd',
    height: IMAGE_SCALE * 80,
    width: IMAGE_SCALE * 250,
    scale: 0.8,
    left: 0.32,
    top: 0.52,
  },
  {
    image: makeCompanyImageUrl('aiflow.png'),
    name: 'AI Flow',
    height: IMAGE_SCALE * 60,
    width: IMAGE_SCALE * 240,
    scale: 1.9,
    left: 0.2,
    top: 0.1,
  },
  {
    image: makeCompanyImageUrl('bitdefender.png'),
    name: 'Bitdefender',
    height: IMAGE_SCALE * 200,
    width: IMAGE_SCALE * 244,
    scale: 0.8,
    left: 0.5,
    top: 0.7,
  },
  {
    image: makeCompanyImageUrl('metro.png'),
    name: 'Metro Digital',
    height: IMAGE_SCALE * 50,
    width: IMAGE_SCALE * 335,
    scale: 0.6,
    left: 0.09,
    top: 0.85,
  },
  {
    image: makeCompanyImageUrl('bosch.png'),
    name: 'Bosch',
    height: IMAGE_SCALE * 50,
    width: IMAGE_SCALE * 215,
    scale: 0.6,
    left: 0.7,
    top: 0.7,
  },
  {
    image: makeCompanyImageUrl('metaphysic.png'),
    name: 'Metaphysic',
    height: IMAGE_SCALE * 70,
    width: IMAGE_SCALE * 360,
    scale: 1.6,
    left: 0.2,
    top: 0.39,
  },
  {
    image: makeCompanyImageUrl('bp.png'),
    name: 'BP',
    height: IMAGE_SCALE * 100,
    width: IMAGE_SCALE * 100,
    scale: 0.7,
    left: 0.8,
    top: 0.45,
  },
];
