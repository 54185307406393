import React from 'react';
import {
  BookOpen,
  Terminal,
  Send,
  Book,
  Database,
  Clipboard,
  ShoppingCart,
  MapPin,
  Users,
  Paperclip,
  Cpu,
  MessageCircle,
} from 'react-feather';
import styled from 'styled-components';
import { Subtitle } from 'components/blog';
import Title from 'components/blog/title';
import Heading from 'components/common/heading';
import Link from 'components/common/link';
import Roadmap from 'components/common/roadmap';
import { RoadmapItem } from 'components/common/roadmap/types';
import Container from 'components/container';
import PublicLayout from 'components/layouts/Public';
import { GOOGLE_DRIVE_RESUME_LINK_ID, GOOGLE_DRIVE_RESUME_URL } from 'constants/urls';
import ContactMe from 'containers/contactMe';
import { theme } from 'utils/theme';

const FullWidthDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: left;
`;

export const EDUCATION_EVENTS: RoadmapItem[] = [
  {
    title: 'Master’s in Data Science',
    subtitle: 'TU Delft',
    content: [''],
    dateText: 'Sep 2020 - Jul 2023 | Netherlands',
    contentStyle: { background: 'white', color: '#111827' },
    iconStyle: { background: theme.customColors.primary, color: 'white' },
    icon: <BookOpen />,
  },
  {
    title: 'Bachelor’s in Computer Science',
    subtitle: 'Babes-Bolyai University | GPA 9.65',
    content: [''],
    dateText: 'Nov 2017 – Jul 2020 | Romania',
    contentStyle: { background: 'white', color: '#111827' },
    iconStyle: { background: theme.customColors.secondary, color: 'white' },
    icon: <Book />,
  },
  {
    title: 'Mathematics and Computer Science',
    subtitle: 'Gheorghe Vrănceanu High School',
    content: [''],
    dateText: 'Sep 2013 – Jun 2017 | Romania',
    contentStyle: { background: 'white', color: '#111827' },
    iconStyle: { background: theme.customColors.lightGray, color: 'white' },
    icon: <Paperclip />,
  },
];

export const CLIENT_TESTIMONIALS: string[] = [
  `"I really had the confidence to trust in you to make the right decision. I wouldn’t doubt any technical decision that we have made in the last months."`,
  `"Mihai is ambitious and a quick learner. A strong team leader who can make product recommendations and put them into practice."`,
  `"AutoML is becoming big in the IT industry. AI Flow is a competent and resilient team. I trust Mihai he can make progress fast and make a business of this."`,
  `"You did a FANTASTIC job on the user flows! Everything makes clear, logical sense and it's pretty much exactly as I imagined it. Thank you!"`,
];

export const SKILLS: RoadmapItem[] = [
  {
    title: 'Technical',
    subtitle: '',
    content: [
      `Data Structures | Machine Learning | Algorithms |
    Graph Theory | OOP`,
      `Proficient in TypeScript, Python, C/C++, Git, Docker`,
      `Working Full-Stack with Django | React | NEXT.js |
      AWS/Heroku/DigitalOcean Deployments |
      Operating Systems | Design patterns | Testing`,
      `Experience with PyTorch, Keras, Pandas, Redis,
      RabbitMQ, GCloud, CI/CD tools, GraphQL, Postgres`,
    ],
    dateText: '',
    contentStyle: { background: 'white', color: '#111827' },
    iconStyle: { background: theme.customColors.warning, color: 'white' },
    icon: <Cpu />,
  },
  {
    title: 'Soft',
    subtitle: '',
    content: [
      `Problem Solving, Client Communication, Adaptability,
      Project Management, Leadership, Team Work`,
    ],
    dateText: '',
    contentStyle: { background: 'white', color: '#111827' },
    iconStyle: { background: theme.customColors.primary, color: 'white' },
    icon: <Clipboard />,
  },
  {
    title: 'What are my clients saying?',
    subtitle: '',
    content: CLIENT_TESTIMONIALS,
    dateText: '',
    contentStyle: { background: 'white', color: '#111827' },
    iconStyle: { background: theme.customColors.success, color: 'white' },
    icon: <MessageCircle />,
  },
];

export const WORK_EVENTS: RoadmapItem[] = [
  {
    title: 'Lead Machine Learning Engineer',
    subtitle: 'Metaphysic.ai',
    content: [
      `In charge of the ML team. My main goal is to productionize research projects and lead the team towards longer-term goals.`,
    ],
    dateText: 'Apr 2023 - Present | Remote',
    contentStyle: { background: 'white', color: '#111827' },
    iconStyle: { background: theme.customColors.success, color: 'white' },
    icon: <Cpu />,
  },
  {
    title: 'Senior Machine Learning Engineer',
    subtitle: 'Metaphysic.ai',
    content: [
      `Working with generative AI and advanced ML models. Leading the development of models and infrastructure for hyperrealistic video content creation.`,
      `Reduced the existing ML pipeline time and cost by 45%.`,
    ],
    dateText: 'Aug 2023 - Apr 2023 | Remote',
    contentStyle: { background: 'white', color: '#111827' },
    iconStyle: { background: theme.customColors.success, color: 'white' },
    icon: <Cpu />,
  },
  {
    title: 'Founder & Lead Engineer',
    subtitle: 'AI Flow',
    content: [
      `Working with small and medium companies, building deep tech AI products from 0, extending existing projects, and taking 100% ownership of the technical part, so you can focus on your business. `,
      `Having a deep understanding of AI and Machine Learning, I also do consulting for high-growth companies.`,
      `Clients include BP, ExoMatter.ai, colayer.io, deinestudienfinanzierung.de and Interflexion.`,
    ],
    dateText: 'Jan 2021 - Present | Remote',
    contentStyle: { background: 'white', color: '#111827' },
    iconStyle: { background: theme.customColors.secondary, color: 'white' },
    icon: <Send />,
  },
  {
    title: 'Senior Software Engineer',
    subtitle: 'Freelance',
    content: [
      `Worked on AI, Cloud, Data Engineering, and Full Stack projects. Handling the whole product lifecycle, including planning, implementation, and deployment.`,
      `Clients include interflexion.com, collectoralerts.com, and perfectlypitched.co. I worked with them to build features in Node.js, Django, Next.js, and React, and scaled their infrastructure on Google Cloud. Built apps from scratch with Next.js - GraphQL}- PostgreSQL, deployed on Digital Ocean.`,
    ],
    dateText: 'Oct 2020 - Present | Remote',
    contentStyle: { background: 'white', color: '#111827' },
    iconStyle: { background: theme.customColors.primary, color: 'white' },
    icon: <Users />,
  },
  {
    title: 'Software Engineer & AI Researcher',
    subtitle: 'Google',
    content: [
      `Did research on feature selection and built a tool that makes Machine Learning iterations up to 70% faster. Clients included Ads and Cloud.`,
      `Improved the user experience in Search and Maps, by building data analysis tools for Geo Location. Used C++, MapReduce, and SQL to build a distributed data pipeline for analyzing and fixing locations with issues.`,
    ],
    dateText: 'Sep 2022 - Aug 2023 | Zürich, Switzerland',
    contentStyle: { background: 'white', color: '#111827' },
    iconStyle: { background: theme.customColors.warning, color: 'white' },
    icon: <MapPin />,
  },
  {
    title: 'Software Engineer',
    subtitle: 'Bloomberg',
    content: [
      `Built a sharing feature in the internal collaboration tool with 200K daily users. Worked Full-Stack with JavaScript and TypeScript, leading the project from start to delivery.`,
      `Moved existing services to Docker,making development flows smoother, impacting more than 300 engineers. Built a data cache layer that currently serves 200 requests/second, with C++, Python, Redit and RabbitMQ.`,
    ],
    dateText: 'Jul 2020 - Sep 2022 | London, UK & New York, US',
    contentStyle: { background: 'white', color: '#111827' },
    iconStyle: { background: theme.colors.black, color: 'white' },
    icon: <Terminal />,
  },
  {
    title: 'Software Engineer',
    subtitle: 'METRO Systems',
    content: [
      `Used machine learning to do customer segmentation and built a
      recommendation system for Metro’s wholesale business.`,
      `Designed a scalable Enterprise Resource Planning architecture in
      order to support data-driven decisions.`,
    ],
    dateText: 'Sept 2019 – Dec 2019 | Cluj-Napoca, RO',
    contentStyle: { background: 'white', color: '#111827' },
    iconStyle: { background: theme.customColors.warning, color: 'white' },
    icon: <ShoppingCart />,
  },
  {
    title: 'Data Scientist',
    subtitle: 'Bosch',
    content: [
      `Reduced analysis time from hours to minutes by applying anomaly detection in videos with thermal events. Built a ML model in Keras and Python.`,
    ],
    dateText: 'Jul 2019 – Sep 2019 | Cluj-Napoca, RO',
    contentStyle: { background: 'white', color: '#111827' },
    iconStyle: { background: theme.customColors.error, color: 'white' },
    icon: <Database />,
  },
  {
    title: 'Teacher at "Hai la Olimpiada"',
    subtitle: '',
    content: [
      `Helped high school students who were interested in the National
      Olympiad in Informatics by presenting various algorithms and solving
      problems.`,
    ],
    dateText: 'Feb 2020 | Cluj-Napoca, RO',
    contentStyle: { background: 'white', color: '#111827' },
    iconStyle: { background: theme.customColors.primary, color: 'white' },
    icon: <Clipboard />,
  },
];

const ResumePage = () => {
  return (
    <PublicLayout title="Resume">
      <Container centerContent py="20px" pylg="20px">
        <FullWidthDiv>
          <Title margin="15px 0 15px 40px">Resume | Mihai Anton</Title>
        </FullWidthDiv>
        <FullWidthDiv>
          <Subtitle margin="15px 0 15px 40px">
            View on&nbsp;
            <Link
              id={GOOGLE_DRIVE_RESUME_LINK_ID}
              color={theme.customColors.linkBlue}
              href={GOOGLE_DRIVE_RESUME_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Drive
            </Link>
          </Subtitle>
        </FullWidthDiv>
      </Container>
      <Container
        backgroundColor={theme.customColors.lightGraySemiTransparent}
        centerContent
        py="20px"
        pylg="20px"
      >
        <FullWidthDiv>
          <Heading margin="15px 0 15px 20px" level={200}>
            Work Experience
          </Heading>
        </FullWidthDiv>
        <Roadmap events={WORK_EVENTS} />

        <FullWidthDiv>
          <Heading margin="15px 0 15px 20px" level={200}>
            Education
          </Heading>
        </FullWidthDiv>
        <Roadmap events={EDUCATION_EVENTS} />

        <FullWidthDiv>
          <Heading margin="15px 0 15px 20px" level={200}>
            Skills
          </Heading>
        </FullWidthDiv>
        <Roadmap events={SKILLS} />
      </Container>
      <Container>
        <ContactMe />
      </Container>
    </PublicLayout>
  );
};

export default ResumePage;
